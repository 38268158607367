<template>
  <div>
    <b-row>
      <b-col cols="12">
        <input 
          type="date"
          v-model="inputValue"
          placeholder="yyyy-mm-dd" 
          :disabled="disabled"
          @input="handleInput"
        />

        <!-- A dummy input, hidden, to use the HTML validation trigger and show error message if needed -->
        <input
          v-if="onValidate"
          ref="input"
          class="dummy-input w-50"
          :value="inputValue"
          required
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";
import { dateToEnglishDashString } from "@/utils/commons";

export default {
  mixins: [DetailUpdateMixin],
  props: {
    value: {
      type: Date,
      default: null,
    },
    field: {
      type: String
    },
    onChange: {
      type: Function,
      default: () => {}
    },
    onValidate: {
      type: Function,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      inputValue: ''
    };
  },
  mounted() {
    this.inputValue = this.value ? dateToEnglishDashString(this.value) : null;
  },
  methods: {
    handleInput() {
      clearTimeout(this.inputTimer);
      this.inputTimer = setTimeout(() => {
        this.valueInStore = this.inputValue;
        this.validateDate(); // Call validation function after updating the model
      }, 500); // Delay in milliseconds, adjust as needed
    },
    validateDate() {
      if (this.onValidate) {
        this.onValidate(this.$refs.input, new Date(this.valueInStore));
      }
    }
  },
  computed: {
    valueInStore: {
      set: function(value) {
        const data = {}
        data[this.field] = new Date(value);
        this.$store.dispatch(this.storeEditAction, data);
      },
      get: function() {
        return this.$store.getters[this.storeEditedGetter][this.field] ? 
          dateToEnglishDashString(this.$store.getters[this.storeEditedGetter][this.field]) :
          this.$store.getters[this.storeReadGetter][this.field]?.slice(0, 10)
      }
    }
  },
  watch: {
    valueInStore(newvalue, oldValue) {
      if (this.inputValue !== newvalue){
        this.inputValue = newvalue;
      }
      this.onChange(new Date(newvalue), new Date(oldValue));
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
